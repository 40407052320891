body,
#ubiqubeMSA {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

body {
  padding-right: 0 !important;
  overflow-y: auto;
  overflow-x: hidden;
}
